import ArrowIcon from '@/components/icons/ArrowIcon';
import { useRouter } from 'next/router';
const HomeBannerSwiperSkeleton = () => {
  const { locale } = useRouter();
  //const [swiper, set_swiper] = useState({});
  return (
    <div
      dir={locale === 'en' ? 'ltr' : 'rtl'}
      className='banner-swiper'
    >

          <div className='block'>
              {/* background image */}
              <div className='h-[368px] w-full rounded-xl sm:h-[328px]'></div>
              <div className='absolute left-0 top-0 z-50 h-full w-full rounded-xl bg-gray-200'>
                <div className='relative top-1/2 mx-auto w-11/12 -translate-y-1/2 text-white max-sm:py-3'>
                  <p className='mb-3 flex justify-between max-sm:flex-col-reverse max-sm:gap-12 sm:mb-5 sm:items-center'>
                    <span className='animate-pulse h-5 w-20 bg-gray-300 rounded max-sm:ps-3 max-sm:text-xs'></span>
                    <span className='flex items-center gap-10 max-sm:justify-end max-sm:gap-5'>
                      <ArrowIcon
                        className={`${
                          locale === 'en' ? 'rotate-90' : '-rotate-90'
                        } text-gray-500`}
                      />
                      <ArrowIcon
                        className={`${
                          locale === 'en' ? '-rotate-90' : 'rotate-90'
                        } text-gray-500`}
                      />
                    </span>
                  </p>
                  {/* slider title */}
                  <p className='animate-pulse mb-3 h-6 w-32 bg-gray-300 rounded max-sm:ps-3 sm:mb-6'></p>
                  {/* slider subtitle */}
                  <p className='animate-pulse text-xl h-6 w-20 bg-gray-300 rounded max-sm:mb-6 max-sm:ps-3'></p>
                  {/* slider button */}
                  <div className='animate-pulse mt-1.5 inline-block rounded-md bg-gray-300 px-11 py-4 max-sm:ms-3 sm:mt-9 sm:py-5'></div>
                </div>
              </div>
          </div>

    </div>
  );
};

export default HomeBannerSwiperSkeleton;
