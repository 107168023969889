import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrandBreakPoint } from '@/constants/BreakPoints';

import { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import ProductTitle from '@/components/home/ProductTitle';

const BrandsSkeleton = ({ numberOfBrands = 6 }) => {
  const { locale } = useRouter();
  const [swiper, set_swiper] = useState(false);
  console.log('numberOfBrands', numberOfBrands);
  return (
    numberOfBrands > 0 && (
      <>
        <ProductTitle
          title={locale === 'en' ? 'Our Brands' : 'العلامات التجارية'}
          swiper={swiper}
        />
        <Swiper
          key={locale}
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          breakpoints={BrandBreakPoint}
          loop
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
        >
          {[...Array(numberOfBrands)]?.map((_, idx) => {
            return (
              <SwiperSlide key={idx}>
                <div className='flex h-auto max-h-16 min-h-16 w-full animate-pulse  items-center justify-center rounded border p-1'>
                  {/* <div className='w-24 h-10 bg-gray-300 rounded-md'></div> */}
                  <svg
                    class='w-1/3 py-[1rem] text-gray-200 dark:text-gray-600'
                    aria-hidden='true'
                    xmlns='http:www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 20 18'
                  >
                    <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
                  </svg>
                </div>{' '}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    )
  );
};

export default BrandsSkeleton;
