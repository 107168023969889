import React from 'react';

const StaticBannersSkeleton = ({numberOfBanners}) => {
  console.log("numberOfBanners", numberOfBanners);
  return (
    numberOfBanners > 0 && (
      <div className={`grid gap-4 sm:grid-cols-${numberOfBanners} md:my-11`}>
        {[...Array(numberOfBanners)]?.map((_, i) => {
          return (
            <div
              key={i}
              className='relative block'
            >
              {/* background image */}
              <div className='w-full h-80'></div>
              <div className='absolute start-0 top-0 z-50 flex h-full w-full flex-col justify-center rounded-xl bg-gray-200 ps-7 text-white sm:ps-14 sm:*:mb-3'>
                {/* headers */}
                <p className='animate-pulse bg-gray-300 rounded h-2 w-20 text-base max-sm:mb-3 sm:text-xs sm:font-medium'></p>
                <p className='animate-pulse p bg-gray-300 rounded h-4 w-32 text-xl font-medium uppercase sm:font-semibold'></p>
                <p className='animate-pulse p bg-gray-300 rounded h-4 w-36 text-xl font-medium uppercase sm:font-semibold'></p>
                {/* button */}
                <div className='animate-pulse mt-4 block w-32 h-10 rounded-md bg-gray-300 dark:bg-gray-600 py-1.5 text-center text-sm font-medium text-white max-sm:hidden sm:p-2'></div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default StaticBannersSkeleton;