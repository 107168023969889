import { useRouter } from 'next/router';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import Link from 'next/link';
import HomeBannerSwiperSkeleton from './HomeBannerSwiperSkeleton';

const MainBannersSkeleton = ({ data }) => {
  const { locale } = useRouter();

  const activeMainBanners = data?.main_banners?.value?.filter(
    (item) => item?.active
  );

  return (
    <div className='grid grid-cols-12 items-start gap-5 sm:my-5'>
      <div
        className='h-full relative col-span-full max-sm:hidden sm:col-span-4 md:col-span-3 lg:col-span-2'
      >
        {/* slider side banner */}
        <div className='h-full max-h-[504px] w-full rounded-xl'></div>
        <div className='absolute left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-around rounded-xl bg-gray-200 dark:bg-gray-600'>
          <div className='flex flex-col items-center'>
            {activeMainBanners?.[0]?.[`title-${locale}`] && (
              <p className='animate-pulse mb-3 h-5 w-20 bg-gray-300 dark:bg-gray-600 rounded'></p>
            )}
            {activeMainBanners?.[0]?.[`subtitle-${locale}`] && (
              <p className='animate-pulse mb-3 h-6 w-24 bg-gray-300 dark:bg-gray-600 rounded'></p>
            )}
            {activeMainBanners?.[0]?.[`description-${locale}`] && (
              <p className='animate-pulse h-6 w-28 bg-gray-300 dark:bg-gray-600 rounded'></p>
            )}
          </div>
          {activeMainBanners?.[0]?.[`button-${locale}`] && (
            <div className='animate-pulse w-[90%] h-10 inline-block rounded-md bg-gray-300 dark:bg-gray-600 p-3'></div>
          )}
        </div>
      </div>

      <div className='col-span-full grid grid-cols-3 gap-5 sm:col-span-8 md:col-span-9 lg:col-span-10'>
        <div className='relative col-span-full'>
          <HomeBannerSwiperSkeleton />
        </div>

        {/* slider bottom banners */}
        <div className='col-span-full flex grid-cols-3 gap-4 max-sm:overflow-x-auto sm:grid gray-scroll'>
          {activeMainBanners?.slice(1).map((banner, i) => {
            return (
              <Link
                key={i}
                href={handleLinkByType(banner?.url, banner?.type)}
                className='relative max-sm:min-w-64 max-sm:max-w-64'
              >
                <div className='h-36 w-full rounded-xl sm:h-[154px]'></div>
                <div className='absolute start-0 top-0 z-50 h-full w-full rounded-xl bg-gray-200 dark:bg-gray-600'>
                  <div className='ps-4 pt-5'>
                    <p className='animate-pulse h-5 w-20 bg-gray-300 rounded mb-3'></p>
                    <p className='animate-pulse h-6 w-24 bg-gray-300 rounded mb-3'></p>
                    <p className='animate-pulse h-6 w-32 bg-gray-300 rounded'></p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainBannersSkeleton;
